<template>
  <div class="QuitManagement">
    <div style="display:flex;">
      <div
        :style="{
          width: '230px',
          'min-width': '230px',
          height: clientH,
          'border-right': '1px solid #efefef'
        }"
        :autoshow="true"
      >
        <a-tree
          :treeData="treeData"
          @select="onSelect"
          :selectedKeys="departmentSel"
        ></a-tree>
      </div>
      <div class="QuitManagement-content">
        <div class="serach_box">
          <a-form layout="inline" style="width:80%;">
            <a-form-item>
              <a-tree-select
                style="min-width: 220px;max-width:360px"
                :treeData="posts_type_arr"
                :value="posts_type"
                @change="onChangeTree"
                treeCheckable
                treeNodeFilterProp="title"
                :showCheckedStrategy="SHOW_PARENT"
                placeholder="请选择"
              />
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 120px"
                v-model="needs_type"
                @change="change"
                placeholder="需求类型"
              >
                <a-select-option
                  v-for="(item, k) in needs_type_arr"
                  :key="k"
                  :value="item.FieldValue"
                  >{{ item.FieldName }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 120px"
                v-model="progress_type"
                placeholder="完成状态"
              >
                <a-select-option
                  v-for="(item, k) in progress_type_arr"
                  :key="k"
                  :value="item.FieldValue"
                  >{{ item.FieldName }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-range-picker
                style="width:220px;"
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                allowClear
                @change="changeTime"
              />
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 120px"
                v-model="pressing_type"
                placeholder="紧急程度"
              >
                <a-select-option
                  v-for="(item, k) in pressing_type_arr"
                  :key="k"
                  :value="item.FieldValue"
                  >{{ item.FieldName }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-input
                placeholder="请输入"
                style="width:200px;"
                v-model="classify_name"
              />
            </a-form-item>
            <a-form-item>
              <a-button style="margin-right:20px" @click="resetBtn()"
                >重置</a-button
              >
              <a-button type="primary" @click="serachBtn()">查询</a-button>
            </a-form-item>
          </a-form>
          <router-link
            tag="div"
            class="map-position ant-menu-item-selected"
            to="/jobStatistics"
          >
            <a-icon
              type="bar-chart"
              style="font-size:18px;vertical-align: middle;"
            />
            招聘统计
          </router-link>
        </div>
        <a-table
          :columns="columns"
          :dataSource="data"
          :pagination="false"
          :customRow="rowClick"
          :scroll="{ x: '150%' }"
          style="margin-top:10px;border:1px solid #efefef"
        >
          <template slot="action" slot-scope="columnsInfo">
            <a-tooltip placement="left" overlayClassName="tooltipBg">
              <!-- trigger="click" -->
              <template slot="title">
                <div style="padding: 0px 4px;">
                  <p class="hoverColor" @click="stopFn(columnsInfo)">
                    停止招聘{{ columnsInfo.key }}
                  </p>
                  <p
                    class="hoverColor"
                    @click="delFn(columnsInfo)"
                    style="margin-top:2px;"
                  >
                    删除{{ columnsInfo.age }}
                  </p>
                </div>
              </template>
              <a-icon
                type="ellipsis"
                style="font-size: 28px;font-weight: 800;"
              />
            </a-tooltip>
          </template>
          <template slot="footer" slot-scope="currentPageData">
            <div style="font-weight:bold;">
              <span style="margin-right:20px;"> 合计:</span> {{ '36' }}
            </div>
          </template>
        </a-table>
        <div style="margin: 20px 0;float:right">
          <a-pagination
            showQuickJumper
            :defaultCurrent="1"
            :total="total"
            :pageSize="pagesize"
            @change="pageChange"
          />
        </div>
      </div>
    </div>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>
  </div>
</template>
<script>
const columns = [
  {
    title: 'Full Name',
    width: 180,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left'
  },
  { title: 'Age', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
  { title: 'Column 1', dataIndex: 'address', key: '1' },
  { title: 'Column 2', dataIndex: 'address', key: '2' },
  { title: 'Column 3', dataIndex: 'address', key: '3' },
  { title: 'Column 4', dataIndex: 'address', key: '4' },
  { title: 'Column 5', dataIndex: 'address', key: '5' },
  { title: 'Column 6', dataIndex: 'address', key: '6' },
  { title: 'Column 7', dataIndex: 'address', key: '7' },
  { title: 'Column 8', dataIndex: 'address', key: '8' },
  {
    title: 'Action',
    key: 'operation',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'action' }
  }
]

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York Park'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 40,
    address: 'London Park'
  }
]
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import moment from 'moment'
export default {
  name: 'QuitManagement',
  data () {
    return {
      SHOW_PARENT,
      treeData: [],

      spinning: false,
      clientH: '400px',
      //table
      data,
      columns,
      //搜索
      posts_type_arr: [], //岗位
      posts_type: [],
      progress_type_arr: [], //完成状态
      progress_type: undefined,
      pressing_type_arr: [], //紧急程度
      pressing_type: undefined,
      needs_type_arr: [], //需求类型
      needs_type: undefined,
      classify_name: '', //input输入框
      startDate: '',
      endDate: '',
      departmentSel: [], //选中的部门
      selectVal: '', //选中的部门Id
      pagesize: 15, //每页5条
      index: 1, //页码
      total: 1 //总数
    }
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 248 + 'px'
    if (window.sessionStorage.getItem('slePerson')) {
      this.treeData = JSON.parse(window.sessionStorage.getItem('slePerson'))
    } else {
      this.$axios.get(8002, { Pid: 0 }, res => {
        if (res.data.list) {
          this.treeData = res.data.list
          window.sessionStorage.setItem(
            'slePerson',
            JSON.stringify(res.data.list)
          )
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    }
    if (!sessionStorage.getItem('posts_type_arr')) {
      this.$axios.get(8007, {}, res => {
        console.log(res)
        if (res.data.code == 1) {
          this.posts_type_arr = res.data.data
          window.sessionStorage.setItem(
            'posts_type_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    } else {
      this.posts_type_arr = JSON.parse(
        window.sessionStorage.getItem('posts_type_arr')
      )
    }

    //获取需求类型
    this.$axios.get(8004, {}, res => {
      if (res.data.code == 1) {
        this.needs_type_arr = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })
    //获取完成状态
    this.$axios.get(8005, {}, res => {
      if (res.data.code == 1) {
        this.progress_type_arr = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })
    //紧急程度
    if (sessionStorage.getItem('pressing_type_arr')) {
      this.pressing_type_arr = JSON.parse(
        sessionStorage.getItem('pressing_type_arr')
      )
    } else {
      this.$axios.get(8006, {}, res => {
        if (res.data.code == 1) {
          this.pressing_type_arr = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }

    this.tableFn()
  },
  methods: {
    //选中的部门
    onSelect (selectedKeys, info) {
      window.console.log(
        selectedKeys,
        info,
        info.node.dataRef.value,
        info.node.dataRef.title
      )
      this.departmentSel = selectedKeys
      this.selectVal = info.node.dataRef.value
    },
    //选择岗位
    onChangeTree (value) {
      console.log(value)
      this.posts_type = value
    },
    change (val) {
      console.log(
        this.posts_type,
        this.pressing_type,
        this.pressing_type,
        this.needs_type,
        this.classify_name,
        this.selectVal,
        this.startDate,
        this.endDate,
        this.index
      )
      console.log(val)
    },
    //搜索时间
    changeTime (value) {
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
      // console.log(this.startDate,this.endDate)
    },
    //分页
    pageChange (pageNumber) {
      console.log(pageNumber)
      this.index = pageNumber
      this.tableFn()
    },
    //重置
    resetBtn () {
      console.log(
        this.posts_type,
        this.needs_type,
        this.progress_type,
        this.pressing_type
      )
      this.progress_type = undefined
      this.needs_type = undefined
      this.posts_type = ''
      this.pressing_type = undefined
      this.departmentSel = []
      this.classify_name = ''
      this.startDate = ''
      this.endDate = ''
      this.index = 1
      // this.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
    },
    //搜索按钮
    serachBtn () {
      console.log(
        this.posts_type,
        this.pressing_type,
        this.pressing_type,
        this.needs_type,
        this.classify_name,
        this.selectVal,
        this.startDate,
        this.endDate
      )
      if (
        !this.posts_type ||
        !this.pressing_type ||
        !this.pressing_type ||
        !this.needs_type ||
        !this.classify_name ||
        !this.selectVal ||
        !this.startDate ||
        !this.endDate
      ) {
        that.$message.error('都为空不请求!')
        return false
      }
      this.tableFn()
    },
    // 点击table
    rowClick: (record, index) => {
      return {
        on: {
          click: () => {
            console.log(record, index)
          }
        }
      }
    },
    //停止招聘
    stopFn (val) {
      console.log(val)
    },
    delFn (val) {
      console.log(val)
    },
    //请求ajax
    tableFn (
      index = 1,
      progress_type = '',
      needs_type = '',
      posts_type = '',
      pressing_type = '',
      selectVal = '',
      startDate = '',
      endDate = ''
    ) {
      let that = this
      that.spinning = true
      that.$axios.get(
        9971,
        {
          DepartmentId: that.selectVal, //部门id
          Status: that.progress_type, //完成状态
          PositionLabel: that.posts_type, //职位Id
          DemandType: that.needs_type, //需求类型
          StartDate: that.startDate,
          EndDate: that.endDate,
          UrgentLavel: pressing_type, //紧急程度
          DemandUserId: JSON.parse(window.sessionStorage.getItem('userInfo'))
            .uid,
          p: that.index, //页码
          pagesize: 15
        },
        res => {
          that.spinning = false
          console.log(res.data)
          if (res.data.code != 1) {
            that.$message.error(res.data.msg)
            return false
          }
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.QuitManagement {
  margin-top: 21px;
  .QuitManagement-content {
    margin-left: 10px;
    width: 100%;
    .serach_box {
      position: relative;
      .map-position {
        position: absolute;
        top: 4px;
        right: 0px;
      }
      .item-label {
        margin-right: 12px;
        margin-bottom: 12px;
        width: 160px;
      }
    }
  }
}
</style>
